/* 
div {
    font-size: 40px;
    font-weight: 5000;
    color: var(--teal-run3);
}

p {
    font-size: 40px;
    color: var(--teal-run2);
} */

label {
    font-size: var(--main-title-font-size);
    font-weight: 5000;
    font-style: italic;
    color: var(--teal-run3);
    padding-right: 20px;
}

input {
    font-size: var(--main-text-font-size);
    font-weight: 5000;
    font-style: italic;
    color: black;
}

button {
    font-size: var(--main-text-font-size);
    font-weight: 5000;
    font-style: italic;
    color: black;
    width: 300px;
}



/* .sr-only {
    text-align: center;
    margin: auto;
} */

.form-control {
    padding-bottom: 2rem;
    text-align: center;
    margin: auto;
}

.btn-submit { 
    text-align: center;
    margin: auto;
    width: 50%;
    border: 3px solid green;
    padding: 10px;
}
