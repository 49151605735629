
ul {
  list-style: none;
}

a {
  text-decoration: none;
}

:root {
  --dropdown-img-right: 1em;
}

@media screen and (min-width: 1201px) {
  :root {
    --dropdown-img-maxsize: 15rem;
  }
  }

  @media screen and (max-width: 1200px) {
:root {
  --dropdown-img-maxsize: 12rem;
}
}

@media screen and (max-width: 800px) {
  :root {
    --dropdown-img-maxsize: 10rem;
    --dropdown-img-right: 0.5em;
  }
}

@media screen and (max-width: 700px) {
  :root {
    --dropdown-img-maxsize: 8rem;
  }
}

@media screen and (max-width: 600px) {
  :root {
    --dropdown-img-maxsize: 6rem;
    --dropdown-img-right: 0.1em;
  }
}

.menu-trigger img {
  position: absolute;
  display: block;
  top: 0;
  right: var(--dropdown-img-right);
  max-height: var(--dropdown-img-maxsize);
  max-width: var(--dropdown-img-maxsize);
  min-height: 3rem;
  min-width: 3rem;
  border-radius: var(--icon-border-radius);
  overflow: hidden;
  cursor: pointer;
  transition: var(--speed) ease;
}


.menu-trigger img:hover {
  border-radius: var(--icon-border-radius-hovered);
  transition: var(--speed) ease;
  height: 17rem;
  width: 17rem;
  top: 0;
  right: 3.5em;
}


.dropdown-menu {
  position: absolute;
  top: 22rem;
  right: 6rem;
  /* height: 15rem; */
  width: 20rem;
  border-radius: var(--border-radius);
  background-color: var(--teal-run2);
  padding: 3rem 6rem;
}

.dropdown-menu::before {
  content: '';
  position: absolute;
  top: -4rem;
  right: 13rem;
  height: 5rem;
  width: 5rem;
  /* border-radius: var(--border-radius); */
  background-color: var(--teal-run2);
  transform: rotate(45deg);
}

.dropdown-menu.active {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
  transition: var(--speed) ease;
}

.dropdown-menu.inactive {
  opacity: 0;
  visibility: hidden;
  transform: translateY(-2rem);
  transition: var(--speed) ease;
}

.dropdown-menu-ul {
  /* top: 100; */
  /* background-color: black; */
}

h3 {
  width: 100%;
  text-align: center;
  font-size: 44px;
  font-weight: 500;
  color: var(--primary-text-color);
  line-height: 1.2rem;
  padding: 20px 0px;
}

h3 span {
  font-size: 30px;
  font-weight: 400;
  color: var(--primary-text-color);
}

.dropdown-menu ul li {
  font-size: 30px;
  color: var(--teal-run3);
  padding: 2rem 0rem;
  border-top: 1px solid rgba(0, 0, 0, 0.4)
}

.dropdown-menu ul li:hover div{
  /* color: var(--teal-run4); */
  color: white;
  cursor: pointer;
  transform: translateX(1rem);
}

.dropdown-menu ul li:hover img{
  opacity: 1.0;
  cursor: pointer;
}

.dropdownItem {
  display: flex;
  margin: 2rem auto;
}

.dropdownItem img {
  max-width: 10rem;
  margin-right: 1rem;
  opacity: 0.5;
  transition: var(--speed);
}

.dropdownItem div {
  max-width: 10rem;
  margin-right: 2rem;
  transition: var(--speed);
  transform: translateX(0rem);
  padding-top: 1.2em;
}

/* .dropdownItemText {
  color: var(--teal-run3);
} */