  
.my-nav {
    padding-top: var(--navbar-padding-top);
}

.my-nav-link {
    padding-left: var(--navbar-padding-left);
    font-size: var(--navbar-fontsize);
    /* font-size: 100px; */
    color: var(--teal-run3);
}

.my-nav-link:hover {
    color: var(--teal-run4);
}

.nav-bar-row {
    width: var(--navbar-row-width);
    height: 100px;
}