:root {
  --primary-bg: #242526;  
  --secondary-bg: #fff;  
  --primary-text-color: #e9fbe6ff;  
  --secondary-text-color: #cecece;  
  --icon-border-radius: 7rem;
  --icon-border-radius-hovered: 10rem;
  --border-radius: 5rem;
  --speed: 500ms;  

  --teal-base: #1e3931ff;
  --teal-run1: #3e7464ff;
  --teal-run2: #47927bff;
  --teal-run3: #bde9ddff;
  --teal-run4: #cbf0e6ff;

  /* --navbar-row-width: fit-content; */
  --navbar-row-width: 70%;
  --main-title-font-size: 70px;
  --main-text-font-size: 35px;
  --navbar-fontsize: 50px;
  --navbar-padding-left: 50px;
  --navbar-padding-top: 0rem;

  --image-display-size: 12rem;
}

@media screen and (min-width: 1201px) {
:root {
  --navbar-row-width: 70%;
  --main-title-font-size: 36px;
  --main-text-font-size: 25px;
}}

@media screen and (max-width: 1200px) {
:root {
  --navbar-fontsize: 50px;
  --navbar-row-width: 70%;
  --main-title-font-size: 33px;
  --main-text-font-size: 24px;
  --navbar-padding-top: 1rem;
}}

@media screen and (max-width: 1000px) {
:root {
  --navbar-fontsize: 40px;
  --navbar-row-width: 70%;
  --main-title-font-size: 31px;
  --main-text-font-size: 24px;
}}
      
@media screen and (max-width: 850px) {
:root {
  --navbar-fontsize: 35px;
  --navbar-row-width: 70%;
  --main-title-font-size: 30px;
  --main-text-font-size: 22px;
  --navbar-padding-top: 2rem;
  --image-display-size: 9rem;
}}

@media screen and (max-width: 700px) {
:root {
  --navbar-fontsize: 25px;
  --navbar-row-width: 70%;
  --main-title-font-size: 25px;
  --main-text-font-size: 19px;
  --image-display-size: 8rem;
}}

@media screen and (max-width: 600px) {
:root {
  --navbar-fontsize: 20px;
  --navbar-row-width: 65%;
  --main-title-font-size: 20px;
  --main-text-font-size: 15px;
  --image-display-size: 7rem;
}}

@media screen and (max-width: 500px) {
:root {
  --navbar-fontsize: 15px;
  --navbar-row-width: 60%;
  --main-title-font-size: 15px;
  --main-text-font-size: 12px;
  --navbar-padding-left: 40px;
  --image-display-size: 5rem;
}}

@media screen and (max-width: 400px) {
:root {
  --navbar-fontsize: 13px;
  --navbar-row-width: 60%;
  --main-title-font-size: 13px;
  --main-text-font-size: 11px;
  --navbar-padding-left: 30px;
  --image-display-size: 4rem;
}}



body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--primary-bg);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.centered {
    padding-bottom: 2rem;
    text-align: center;
    margin: auto;
}

/* h2 {
  font-size: 70px;
  font-weight: 5000;
  font-style: italic;
  color: var(--teal-run2);
} 

p {
  font-size: 40px;
  color: var(--teal-run2);
} */

h2 {
  font-size: var(--main-title-font-size);
  font-weight: 400;
  color: var(--teal-run2);
  /* font-style: italic; */
}

div p {
  font-size: var(--main-text-font-size);
  color: var(--teal-run3);
}

div {
  font-size: var(--main-text-font-size);
  color: var(--teal-run2);
}

.main-text-section-title {
  max-width: 80%;
  padding-left: .5rem;
}
.main-text-section {
  max-width: 80%;
  padding-left: .5rem;
}




.image-display {
  cursor: pointer;
  transition: var(--speed) ease;
  opacity: 0.5;
  width: var(--image-display-size); 
  height: var(--image-display-size); 
  padding: 1em;
}


.image-display:hover {
  border-radius: var(--icon-border-radius-hovered);
  transition: var(--speed) ease;
  opacity: 1.0;
   /* height: 17rem;
  width: 17rem; */
  /* top: 0;
  right: 3.5em;  */
}

.popup {
  position: fixed;
  top: 40%;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-inner {
  position: relative;
  padding: 32px;
  width: 100%;
  max-width: 600px;
  background-color: white;
}

.popup-inner .close-btn {
  position: absolute;
  top: 16px;
  right: 16px;
}

.my-section-link {
  padding: 0.5em;
  margin: 1em;
  font-size: var(--main-text-font-size);
  color: var(--teal-run4);
  background-color: var(--teal-run1);
  transition: var(--speed) ease;
  opacity: 0.85;

}

.my-section-link:hover {
  color: white;
  background-color: var(--teal-run2);
  transition: var(--speed) ease;
  opacity: 1.0;
}